import React, { useRef, useState, useEffect } from "react"
import { GatsbyGraphQLType, graphql, PageProps } from "gatsby"
import Img, { GatsbyImageProps } from "gatsby-image"
import styled from "styled-components"
import Slider, { Settings } from "react-slick"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app
import Hero, { CarouselItem } from "../components/Layout/Hero"
import Content from "../components/Layout/Content"
import { Heading, Subheading } from "../components/Layout/Hero/style"
import climateChangeBg from "../../static/img/ClimateChange.png"
import whyHempBg from "../../static/img/whyHemp.png"
import hempPartsDesktopImg from "../../static/img/hemp-parts-desktop.jpeg"
import hempPartsMobileImg from "../../static/img/hemp-parts-mobile.jpeg"
import hempProductsDesktopImg from "../../static/img/hemp-products-desktop.jpeg"
import hempProductsMobileImg from "../../static/img/hemp-products-mobile.jpeg"
import gifHempBenefitsMp4 from "../../static/video/gif-hemp-benefits.mp4"
import gifHempBenefitsWebm from "../../static/video/gif-hemp-benefits.webm"
import gifHempBenefitsOgv from "../../static/video/gif-hemp-benefits.ogv"
import zoomIcon from "../../static/img/zoom-icon.svg"
import Button from "../components/Button"
import useWindowSize from "../utils/useWindowSize"
import {
  DualContentContainer,
  ImgContainer,
} from "../components/Layout/Content/style"
import SEO from "../components/SEO"

/**
 * Settings for React-Slick carousel. Check the documentation for
 * property descriptions and additional properties.
 * @link https://react-slick.neostack.com/docs/api
 */
const settings: Settings = {
  dots: true,
  infinite: false,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
}

const imageCarousel: Settings = {
  dots: true,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  arrows: false,
  dotsClass: "slick-dots images",
  centerPadding: "0",
  centerMode: true,
  useTransform: false,
  useCSS: false,
  autoplay: true,
  autoplaySpeed: process.env.GATSBY_CAROUSEL_AUTOPLAY_SPEED
    ? Number(process.env.GATSBY_CAROUSEL_AUTOPLAY_SPEED)
    : 7000,
}

/**
 * A blueprint for an item to be displayed in the content section carousel.
 * @property text The text to be displayed in the carousel slide.
 * @property author The source name/author from which the quote was found.
 * @property href A URL linking to the original content.
 */
interface ContentCarouselItem {
  text: string
  author: string
  href: string
}

const Image = styled(Img)`
  width: 75% !important;
  margin: auto;
  display: block !important;
`

const ImageIcon = styled.img`
  position: absolute;
  z-index: 10;
  top: 0;
  right: calc(13%);
  margin: auto;
  width: calc(5%);
  &:hover {
    opacity: 0.5;
    cursor: zoom-in;
  }
`

const ImageContainer = styled.div`
  position: relative;
  display: block !important;
  max-width: 75%;
  margin: auto;
`

const ClimateChange = ({ data }: any) => {
  console.log("DATA", data)
  const { width } = useWindowSize()
  const {
    hempPartsDesktop,
    hempPartsMobile,
    hempProductsDesktop,
    hempProductsMobile,
  } = data
  const imgSliderRef = useRef(null)
  const [lightbox, setLightbox] = useState({ isOpen: false, image: "" })
  const [lightbox2, setLightbox2] = useState({ isOpen: false, image: "" })

  /**
   * Handles smooth scrolling to a specific location in a page based on the provided id.
   * @param id A string identifier for location of the page to be scrolled to.
   */
  const smoothScrollTo = (id: string) => {
    if (document) {
      document.getElementById(id)?.scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    const video: HTMLVideoElement | any = document?.getElementById("video-gif")
    video.play()
  })

  const mainHeroItem: CarouselItem[] = [
    {
      backgroundImg: "ASk9YOHVte0",
      isVideo: true,
      heading: {
        text: "CLIMATE CHANGE",
        mobileFontAlign: "center",
        desktopFontAlign: "center",
        mobileMargin: "140px 0 0 0",
        desktopMargin: "240px 0 0 0",
        mobileFontSize: "36px",
        desktopFontSize: "96px",
        fontColor: "#FCFBFB",
      },
      subheading: {
        text:
          "According to the International Panel on Climate Change: " +
          '"The range of published evidence indicates that the net damage ' +
          'costs of climate change are likely to be significant and increase over time"',
        mobileFontAlign: "left",
        desktopFontAlign: "left",
        mobileLineHeight: 18,
        desktopLineHeight: 31,
        mobileMargin: "15px 0px",
        desktopMargin: "20px 0px",
        mobileFontSize: "14px",
        desktopFontSize: "24px",
        fontColor: "#FCFBFB",
        maxWidth: "632px",
      },
      btnList: [
        {
          label: "- IPCC",
          isLink: true,
          href: "https://climate.nasa.gov/effects/",
          textDecoration: true,
          mobileFontSize: "18px",
          desktopFontSize: "24px",
          width: "auto",
          desktopMargin: "40px 0 0 0",
        },
        {
          label: "Why Hemp?",
          mobileMargin: "70px 0 0 0",
          width: "100% !important",
          desktopMargin: "110px 0 0 0 !important",
          onClick: () => smoothScrollTo("hemp"),
        },
      ],
    },
  ]

  const subHeroItem: CarouselItem[] = [
    {
      backgroundImg: climateChangeBg,
      heading: {
        text: "THE VERSATILITY OF HEMP IS LIMITED ONLY BY HUMAN CREATIVITY",
        mobileFontAlign: "center",
        desktopFontAlign: "center",
        mobileMargin: "auto 0",
        desktopMargin: "auto auto",
        fontColor: "#FCFBFB",
      },
    },
  ]

  const mainContent = [
    {
      text:
        "Climate change is a threat to all aspects of life; " +
        "it affects the quality of the air we breathe, water we drink, " +
        "the food we eat and the safety of the places we live.",
      author: "WHO",
      href: "https://www.who.int/health-topics/climate-change#tab=tab_1",
    },
    {
      text:
        "Scientists' predictions were accurate: glaciers are melting, sea levels " +
        "are rising and the world is experiencing longer and more intense heat waves.",
      author: "NASA",
      href: "https://climate.nasa.gov/effects/",
    },
    {
      text:
        "According to the 3rd and 4th National Climate Assessment Reports, " +
        "temperatures will continue to rise, we will have more droughts and heat waves, " +
        "and hurricanes will become stronger and more intense.",
      author: "NASA",
      href: "https://climate.nasa.gov/effects/",
    },
    {
      text: "Between 2030-2050 climate change is expected to contribute to increasing mortality.",
      author: "WHO",
      href: "https://www.who.int/health-topics/climate-change#tab=tab_1",
    },
    {
      text:
        "It is predicted that by 2050, the Arctic is likely to melt completely, " +
        "and by 2100, sea levels will rise 1-8 feet.",
      author: "NASA",
      href: "https://climate.nasa.gov/effects/",
    },
  ]

  /**
   * Function to generate an individual slide for the content carousel.
   * @param contentItem An object containing all the required information for generating a slide.
   * @returns JSX for the slide to be displayed.
   */
  const generateContent = (contentItem: ContentCarouselItem) => {
    return (
      <>
        <SEO
          title="Tangho - Climate Change"
          description='According to the International Panel on Climate Change: "The range of published evidence indicates that the net damage costs of climate change are likely to be significant and increase over time"'
          url="/climate"
          image={climateChangeBg}
        />
        <Subheading
          mobileFontSize="18px"
          mobileLineHeight={24}
          desktopFontSize="24px"
          desktopLineHeight={31}
        >
          {contentItem.text}
        </Subheading>
        <Button
          className="climate-content-author"
          label={`- ${contentItem.author}`}
          isLink={true}
          textDecoration={true}
          href={contentItem.href}
        />
      </>
    )
  }

  return (
    <>
      <Hero carouselItems={mainHeroItem} mobileHeight={520} />
      <Content
        mobileMargin="0 0 15px 0"
        backgroundColor="#3F8070"
        textColor="#FCFBFB"
      >
        <Slider className="climate-content-slider" {...settings}>
          {mainContent.map((c: ContentCarouselItem) => generateContent(c))}
        </Slider>
      </Content>
      {/* <Heading
        maxWidth="90%"
        mobileMargin="50px auto 20px auto"
        desktopMargin="30px auto 20px auto"
        desktopFontAlign="center"
        mobileFontAlign="center"
        desktopFontSize="48px"
        desktopLineHeight={50}
      >
        The versatility of hemp is limited only by human creativity
      </Heading> */}
      <video
        style={{
          margin: "48px auto",
          display: "block",
          width: "100%",
          maxWidth: "720px",
        }}
        controls={false}
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        id="video-gif"
      >
        <source src={gifHempBenefitsMp4} type="video/mp4" />
        <source src={gifHempBenefitsOgv} type="video/ogg" />
        <source src={gifHempBenefitsWebm} type="video/webm" />
      </video>
      <DualContentContainer>
        <Content id="hemp" desktopMargin="0 40px">
          <Heading
            maxWidth="600px"
            mobileMargin="20px auto 50px auto"
            desktopMargin="20px auto 50px auto"
            desktopFontAlign="left"
            mobileFontAlign="left"
            desktopFontSize="48px"
            desktopLineHeight={50}
          >
            Why Hemp?
          </Heading>
          <Subheading
            mobileMargin="20px auto 50px auto"
            desktopMargin="20px auto 50px auto"
            mobileFontSize="14px !important"
            mobileLineHeight={18}
            desktopFontSize="24px !important"
            desktopLineHeight={31}
            maxWidth="600px"
            padded="0 0"
          >
            Hemp is one of the most eco-friendly crops: it's low use of
            pesticides, low water consumption, fewer carbon emissions, and root
            system that replenishes the soil makes its culture more sustainable
            than the majority of commodities.
            <br />
            <br />
            Hemp also has suitable mechanical properties: it is lightweight,
            flexible and it has high tensile strength.
          </Subheading>
        </Content>
        <ImageContainer>
          <ImageIcon
            key={0}
            onClick={() =>
              setLightbox({
                isOpen: true,
                image:
                  width && width >= 960
                    ? hempPartsDesktopImg
                    : hempPartsMobileImg,
              })
            }
            src={zoomIcon}
          />
          <Image fluid={hempPartsMobile.childImageSharp.fluid} />
        </ImageContainer>
      </DualContentContainer>
      <Content>
        {/* <ImgContainer
          mobileMargin="-35px auto -44px auto"
          desktopMargin="-35px auto -44px auto"
          mobileSrc={hempPartsMobile}
          desktopSrc={hempPartsDesktop}
        /> */}
      </Content>
      {lightbox.isOpen && (
        <Lightbox
          enableZoom={false}
          mainSrc={lightbox.image}
          onCloseRequest={() => setLightbox({ isOpen: false, image: "" })}
        />
      )}
      <br />
      <br />
      <br />
      <br />
    </>
  )
}

export const query = graphql`
  query {
    hempProductsMobile: file(
      relativePath: { eq: "hemp-products-mobile.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hempProductsDesktop: file(
      relativePath: { eq: "hemp-products-desktop.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hempPartsMobile: file(relativePath: { eq: "hemp-parts-mobile.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hempPartsDesktop: file(relativePath: { eq: "hemp-parts-desktop.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default ClimateChange
